<template>
  <Loader style="flex-grow: 1">
    <div class="contact-page">
      <section class="contact-page__content container">
        <Roles />
        <Lead />
      </section>
      <section v-if="owner" class="contact-page__owner container">
        <div v-html="owner.content"></div>
        <ImageComponent :value="owner.logo" />
      </section>
    </div>
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Lead from '@/components/Project/Lead'
import Roles from '@/components/Project/Roles'
import ImageComponent from '@kvass/media-render/Types/Image'

export default {
  computed: {
    ...mapState('Project', ['item']),
    owner() {
      return this.$path('item.customFields.owner')
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },
  components: {
    Lead,
    Roles,
    ImageComponent,
  },
}
</script>

<style lang="scss">
.contact-page {
  padding-block: 6rem;

  &__roles {
    padding: 2rem;
  }

  &__content {
    .lead {
      margin-inline: initial;
      background: #fafafa;
      padding: 2rem;
    }
  }

  &__owner {
    margin-top: 4rem;
  }

  &__content,
  &__owner {
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(2, 1fr);
    @include respond-below('tablet') {
      grid-template-columns: 1fr;
    }
  }
}
</style>
